<template>
    <section>
        <div class="activities-page  mb-6 pb-6 mt-6">
            <div class="mb-6" data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false">
              <p class="title mb-5">Day trips in Portugal</p>
      <p class="mb-4">Uncover the true beauty of Portugal with a private guide, who’ll take you on a personalized journey to reveal the country’s most captivating secrets. </p>
     <!-- <div class="is-flex is-justify-content-flex-end">
                <div class="mr-2 btn-outlined" @click="scrollLeft">
                  <b-icon icon="arrow-left"></b-icon>
                </div>
                <div class="btn-outlined" @click="scrollright">
                  <b-icon icon="arrow-right"></b-icon>
                </div>
              </div> -->
            </div>

            <ActivitiesContainer
            ref="childComponent"
        :is-full-width="true"
        :Experiences="[
          'D1YH6p0pVThgornEsYhe',
          'GqLu48OlVVI3sH6BwGZm',
          'SfuJZtcgdYvaU2MgTbwj',
          'JDO7uP17SaMlx8Y21gl8',
        '63mFURw6KKq6ZFTmGtMQ',
        'FyBrCr0owDZFSWu7mag7',
        't78nJPOAbsW4DuwDnZa8']"
        :Tickets="[]"
      />
            </div>
    </section>
</template>

<script>
import ActivitiesContainer from '../ActivitiesContainer.vue';
export default{
    components: {
        ActivitiesContainer
    },
    methods: {
    scrollLeft() {
      this.$refs.childComponent.scrollLeft();
    },
    scrollRight() {
      this.$refs.childComponent.scrollRight();
    },
  },
}
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Tenor+Sans&display=swap");
.btn-outlined {
    background-color: transparent;
    border: 1px solid #0e3d4d;
    border-radius: 50px;
    width: -moz-max-content;
    width: max-content;
    padding: 1rem;
    height: 3rem;
    display: flex;
    align-items: center;
  
    .icon {
      color: #0e3d4d;
      height: 1rem;
      width: auto;
    }
    &:hover {
      background-color: #0e3d4d;
      .icon {
        color: white;
      }
    }
  }

  .title,
.title-md {
    font-family: "Playfair Display", serif; 
  color: #0e3d4d;
  font-weight: 500;
}

.title {
  font-size: 2rem;
}

.title-md {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .title {
    font-size: 1.6rem;
}
}

@media screen and (min-width: 1400px) {
  .activities-page{
    padding: 0 25vh;
  }
}


</style>