<template>
  <section>
    <div class="mb-6">
      <div class="container-md mt-3">
      <p class="mb-1 subtitle-sm">Where do you want to go</p>

      <div class="display-flex-space-btw">
        <p class="title">Popular Destinations</p>

        <div class="is-flex is-justify-content-flex-end">
          <div class="mr-2 btn-outlined" @click="scroll_left">
            <b-icon icon="arrow-left"></b-icon>
          </div>

          <div class="btn-outlined" @click="scroll_right">
            <b-icon icon="arrow-right"></b-icon>
          </div>
        </div>
      </div>
      <CardWithText :items="destinations" @openModal="openModal" open-modal />
    </div>
      <!--  <div class="d-container">
        <div v-for="(destination, index) in destinations" :key="index">
          <div
            @click="openModal(destination.id)"
            :class="{ card1: !isMobileView, 'card-mobile': isMobileView }"
            :style="`backgroundImage:url(${destination.img})`"
          >
            <div class="display-flex-space-btw destination-text">
              <p
                :class="{
                  small: !isMobileView,
                  'mb-destination-text': isMobileView,
                }"
              >
                {{ destination.name }}
              </p>

              <div v-if="isMobileView" class="arrow-container">
                <div>
                  <b-icon icon="arrow-right"></b-icon>
                </div>
              </div>
            </div>

            <div class="go-corner" v-if="!isMobileView">
              <div class="go-arrow">
                <b-icon icon="arrow-right"></b-icon>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="mt-5 bg-light ">
      <div class="container-md">
        <div>
          <p class="title mb-6">Our Services</p>
        <!--   <p class="mb-6">
            Discover the ultimate travel experience in Portugal with our
            comprehensive range of services designed to make your journey smooth
            and enjoyable. Whether you’re traveling for leisure or business, we
            take care of every detail so you can focus on creating unforgettable
            memories.
          </p> -->
         <!--  <div class="buttons mb-5">
 <b-button type="is-primary" @click="isQuoteModalActive = true" 
            >Request a quote</b-button>
            <b-button type="is-ghost">Talk to us</b-button>
          </div> -->
         
        </div>
        <div>
          <div class="columns is-multiline  is-centered">
            <div class="column is-4">
              <div class="mb-4 container-column"  data-aos="fade-up"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="false">
                <img class="mb-3 icon-md" src="../assets/signpost.svg" /> 
                <p class="title-md">Private guides</p>
                <div>
                 
                  <p class="subtitle mt-2 mb-2">
                    Experience the destination like a local with our
                    knowledgeable guides who bring the culture and history to
                    life.
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="mb-4 container-column"  data-aos="fade-up"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="false">
                <div>
                  <img class="mb-3 icon-md" src="../assets/bus.svg" />

                  <p class="title-md">Minibus and Bus Renting</p>
                </div>
                <p class="subtitle mt-2 mb-2">
                  Seamless, comfortable, and reliable transportation across
                  Portugal with professional drivers for groups of any size.
                </p>
              </div>
            </div>

            <div class="column is-4">
              <div class="mb-4 container-column" data-aos="fade-up"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="false">
                <div>
                  <img class="mb-3" src="../assets/clipboard.svg" />

                  <p class="title-md">Itinerary planning</p>
                </div>

                <p class="subtitle mt-2 mb-2">
                  We craft bespoke travel plans that cater to your group's
                  preferences, whether it's cultural tours, wine tasting, or
                  outdoor adventures.
                </p>
              </div>
            </div>
            <div class="column is-4">
              <div class="mb-4 container-column" data-aos="fade-up"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="false">
                <div>
                  <img class="mb-3" src="../assets/transfer.svg" />

                  <p class="title-md">Airport transfer</p>
                </div>
                <p class="subtitle mt-2 mb-2">
                  Stress-free arrival and departure with personalized
                  meet-and-greet services.
                </p>
              </div>
            </div>
            <div class="column is-4">
              <div class="mb-4 container-column" data-aos="fade-up"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="false">
                <div>
                  <img class="mb-3" src="../assets/24-support.svg" />

                  <p class="title-md">24/7 Support</p>
                </div>
                <p class="subtitle mt-2 mb-2">
                  We’re here for you at any time, ensuring a hassle-free
                  experience from start to finish.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  <div>
        <div class="d-containe mt-4 pt-5">
          <div class="mb-4 container-column">
            <div class="is-flex">
              <img class="mb-2 mr-2" src="../assets/signpost.svg" />

              <p class="title-md">Private guides</p>
            </div>
            <p class="subtitle mt-2 mb-2">
              Experience the destination like a local with our knowledgeable
              guides who bring the culture and history to life.
            </p>
          </div>

          <div class="mb-4 container-column">
            <div class="is-flex">
              <img class="mb-2 mr-2" src="../assets/bus.svg" />

              <p class="title-md">Minibus and Bus Renting</p>
            </div>
            <p class="subtitle mt-2 mb-2">
              Seamless, comfortable, and reliable transportation across Portugal
              with professional drivers for groups of any size.
            </p>
          </div>

          <div class="mb-4 container-column">
            <div class="is-flex">
              <img class="mb-2 mr-2" src="../assets/clipboard.svg" />

              <p class="title-md">Itinerary planning</p>
            </div>

            <p class="subtitle mt-2 mb-2">
              We craft bespoke travel plans that cater to your group's
              preferences, whether it's cultural tours, wine tasting, or outdoor
              adventures.
            </p>
          </div>

          <div class="mb-4 container-column">
            <div class="is-flex">
              <img class="mb-2 mr-2" src="../assets/transfer.svg" />

              <p class="title-md">Airport transfer</p>
            </div>
            <p class="subtitle mt-2 mb-2">
              Stress-free arrival and departure with personalized meet-and-greet
              services.
            </p>
          </div>

          <div class="mb-4 container-column">
            <div class="is-flex">
              <img class="mb-2 mr-2" src="../assets/24-support.svg" />

              <p class="title-md">24/7 Support</p>
            </div>
            <p class="subtitle mt-2 mb-2">
              We’re here for you at any time, ensuring a hassle-free experience
              from start to finish.
            </p>
          </div>
        </div>
      </div> 
      <div class="container max-desktop">
        <div class="mb-4 mt-2 bg-green container-column has-text-centered">
          <p class="title-md is-size-4">Ready to explore Portugal?</p>

          <p class="subtitle mt-4 mb-5">
            Request a quote today and let us handle the details!
          </p>

          <b-button type="is-primary" @click="isQuoteModalActive = true"
            >Request a quote</b-button
          >
        </div>
      </div>-->
    </div>

    <ActivitiesHomePage />
    <!-- <Process /> -->
    <HpFAQ class="pb-3"/>
    <ReviewsComponent />
    <div class="quote-container mt-6">
      <div class="container-text">
        <p class="title">Start planning your tailor-made holiday</p>

        <p class="title-md mb-6">Speak to one of our travel specialists</p>

        <b-button
          type="is-secondary"
          icon-right="arrow-right"
          class="button-yellow"
          rounded
          @click="isQuoteModalActive = true"
          >Request quote</b-button
        >
      </div>
    </div>

    <b-modal
      :full-screen="isMobileView"
      v-model="isCardModalActive"
      scroll="keep"
    >
      <div class="card">
        <AboutDestination :id="destinationId" />
      </div>
    </b-modal>

    <b-modal
      :full-screen="isMobileView"
      v-model="isQuoteModalActive"
      :width="660"
      class="quote-modal"
      scroll="keep"
    >
      <div class="card">
        <QuoteForm />
      </div>
    </b-modal>
  </section>
</template>

<script>
import ReviewsComponent from "../components/elements/ReviewsComponent.vue";
//import GalleryClients from '../components/elements/GalleryClients.vue'
import ActivitiesHomePage from "./elements/ActivitiesHomePage.vue";
import { HelpersMixin } from "../mixins";
import HpFAQ from "./HpFAQ.vue";
import AboutDestination from "./AboutDestination.vue";
import QuoteForm from "../components/QuoteForm.vue";
import CardWithText from "../components/elements/CardWithText.vue";
//import Process from '../components/Process.vue'
export default {
  mixins: [HelpersMixin],
  components: {
    CardWithText,
    ReviewsComponent,
    ActivitiesHomePage,
    HpFAQ,
    AboutDestination,
    QuoteForm,
    //Process
    // GalleryClients
  },
  metaInfo() {
    return {
      title:
        "Guiders | Travel Concierge Services in Portugal - Group Travel Planning, Private Guides, and Transport",
      meta: [
        {
          name: "description",
          content:
            "Discover top-notch travel concierge services in Portugal. We offer itinerary planning, private guides, and transport for large groups. Request a quote today!",
        },
        {
          name: "keywords",
          content:
            "travel concierge Portugal, group travel services, private transport Portugal, itinerary planning, Portugal private guides",
        },
        {
          property: "og:title",
          content: "Guiders | Travel Concierge Services in Portugal",
        },
        {
          property: "og:description",
          content:
            "Top-notch concierge services for group travel in Portugal, including private guides and transport.",
        },
        {
          property: "og:image",
          content: "https://yourwebsite.com/path-to-your-image.jpg",
        },
        { property: "og:url", content: "https://guiders.py" },
        { property: "og:type", content: "website" },
        { name: "twitter:card", content: "summary_large_image" },
        {
          name: "twitter:title",
          content: "Travel Concierge Services in Portugal",
        },
        {
          name: "twitter:description",
          content:
            "Itinerary planning, private guides, and transport for large groups in Portugal. Request a quote today!",
        },
        {
          name: "twitter:image",
          content: "https://guiders.pt/path-to-your-image.jpg",
        },
        { name: "robots", content: "index, follow" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "theme-color", content: "#ffffff" },
      ],

      link: [
        { rel: "canonical", href: "https://guiders.pt" },
        { rel: "icon", href: "/favicon.ico" },
      ],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            name: "Travel Concierge Services",
            url: "https://guiders.pt",
            logo: "https://guiders.pt/logo.jpg",
            description:
              "We offer premium travel concierge services in Portugal, including group travel planning, private guides, and transportation.",
            address: {
              "@type": "PostalAddress",
              streetAddress: "Av. Coronel Eduardo Galhardo nº 34-1dt",
              addressLocality: "Lisbon",
              addressRegion: "Lisbon",
              postalCode: "1170-105",
              addressCountry: "PT",
            },
            telephone: "+351-920-043-375",
          },
        },
      ],
    };
  },
  data() {
    return {
      isCardModalActive: false,
      isQuoteModalActive: false,
      destinationId: 0,
      services: [
        {
          icon: "../assets/signpost.svg",
          title: "Private guides",
          text: "Experience the destination like a local with our knowledgeable guides who bring the culture and history to life.",
        },
        {
          icon: "../assets/bus.svg",
          title: "Minibus and Bus Renting",
          text: "Seamless, comfortable, and reliable transportation across Portugal with professional drivers for groups of any size.",
        },
        {
          icon: "../assets/clipboard.svg",
          title: "Itinerary planning",
          text: " We craft bespoke travel plans that cater to your group's preferences, whether it's cultural tours, wine tasting, or outdoor adventures.",
        },
      ],
      destinations: [
        {
          id: 1,
          name: "Jerónimos Monastery",
          experiences: ["tILBrMVXOzLveIM403Je", "FyBrCr0owDZFSWu7mag7"],
          tickets: ["tILBrMVXOzLveIM403Je"],
          description:
            "Jerónimos Monastery is an iconic monastery located in Lisbon, Portugal, known for its stunning Manueline architecture and historical significance.",
          img: "https://media1.thrillophilia.com/filestore/hu62osztgz8tryxx9iemlnw0cozr_43999179605_bba1e09faf_o.jpg?w=1080&dpr=2",
        },
        {
          id: 2,
          name: "Belém Tower",
          img: "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.pizzatravel.com.ua%2Fuploads%2F2015%2F24519.jpg&f=1&nofb=1&ipt=23710b998e0a4f51e4e5c8e77dfa86c3a7da975676b2226bc51345a78b479293&ipo=images",
        },
        {
          id: 6,
          name: "São Jorge Castle",
          img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/locations%2Fsjorge.png?alt=media&token=ea8763a8-77fc-4439-aba5-4a682a69ec92",
        },
        {
          id: 4,
          name: "Pena Palace",
          img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20204.png?alt=media&token=1a02460d-4bd6-4a52-a5d9-bf08fb677431",
        },
        {
          id: 8,
          name: "Óbidos Castle",
          img: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmedia.cntraveler.com%2Fphotos%2F5bd240fea480f70a5952c2cf%2F16%3A9%2Fw_2560%2Cc_limit%2FGettyImages-182856050.jpg&f=1&nofb=1&ipt=3250b4ae5af4fb701ff4b5af191c20cf00c3476b171caf97bdf6b9090f0d2843&ipo=images",
        },
        {
          id: 7,
          name: "Alfama District",
          img: "https://descubralisboa.com/wp-content/uploads/2018/10/Casa-dos-Bicos_2.jpg",
        },
        {
          id: 3,
          name: "Sintra National Palace",
          img: "https://firebasestorage.googleapis.com/v0/b/tours-26f02.appspot.com/o/attraction%2Fimage%20207.png?alt=media&token=e5a1e19e-9ca1-4331-8566-e7575ff06c22",
        },
      ],
    };
  },
  methods: {
    scroll_left() {
      let content = document.querySelector(".d-container");

      content.scrollBy({
        top: 0,
        left: -360,
        behavior: "smooth",
      });
    },
    scroll_right() {
      let content = document.querySelector(".d-container");
      content.scrollBy({
        top: 0,
        left: +360,
        behavior: "smooth",
      });
    },
    openModal(id) {
      this.destinationId = id;
      this.isCardModalActive = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Tenor+Sans&display=swap");
.modal .modal-content {
  width: fit-content;
  &::-webkit-scrollbar {
    display: none;
  }
}

.modal .modal-content {
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.modal-content {
  padding: 1.5rem;
}

.has-radius-20 {
  border-radius: 20px;
  height: 30rem;
  width: -moz-fit-content;
  width: auto;
  -o-object-fit: cover;
  object-fit: contain;
}

.button-yellow {
  background-color: #ffc145;
  border: none;
}

.quote-container {
  display: flex;
  margin: 0 -20px;
  min-width: -webkit-fill-available;
  margin-bottom: -39px;
  background-image: url("../assets/section-bg-img.png");
  background-size: cover;
  /* Cover the entire div */
  background-position: center;
  /* Center the background image */
  .container-text {
    width: -webkit-fill-available;
    padding: 50px 15vw;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: -webkit-center;
    .title,
    .title-md {
      color: white !important;
    }
  }
}

.title,
.title-md {
  font-family: "Playfair Display", serif;
  color: #0e3d4d;
  font-weight: 500;
}

.title {
  font-size: 2rem;
}

.title-md {
  font-size: 1.3rem;
}

.bg-light {
  background-color: #30758c08;
  margin: 0 -20px;
  padding: 3.5rem 2rem;
  .d-container {
    margin: 0 -33px;
    padding: 0 27px;
  }
  .container-column {
    padding: 1.5rem 1.2rem;
    display: block;
    position: relative;
    min-width: 262px;
    //#f1f2fb
    background-color: #f8faeca1;
    border-radius: 8px;
    text-align: unset;
    &:hover {
      background-color: rgb(248 250 236 / 50%);
    }
    .title-md{
      font-family: "Inter", sans-serif;
      font-weight: 400;
    }
    img {
      padding: 0.6rem;
    border-radius: 8px;
    // #e9eafe
    background-color: #f2f6d0;
    height: 2.5rem;
    margin-right: 1rem !important;
    display: flex;
    justify-content: space-evenly;
    }
  }
  .bg-green {
    margin-right: 0;
    background-color: transparent;
    border: 1px solid #0e3d4d;
    border-radius: 0;
    .title-md,
    .subtitle {
      color: #0e3d4d !important;
    }
    .button {
      font-weight: 500;
      padding: 0.75rem 1rem;
    }
  }
}

.subtitle {
  font-family: "Inter", sans-serif;
  color: #0e3d4de8;
  font-size: 1rem;
  font-weight: 300;
  &-sm {
    color: rgb(101 142 155);
  }
}

.destination-card {
  height: 15rem;
  width: 12rem;
  border-radius: 20px;
  .destination-name {
    font-family: "Playfair Display", serif;
  }
}

.btn-outlined {
  background-color: transparent;
  border: 1px solid #0e3d4d;
  border-radius: 50px;
  width: -moz-max-content;
  width: max-content;
  padding: 1rem;
  height: 3rem;
  display: flex;
  align-items: center;
  .icon {
    color: #0e3d4d;
    height: 1rem;
    width: auto;
  }
  &:hover {
    background-color: #0e3d4d;
    .icon {
      color: white;
    }
  }
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 46px;
  height: 46px;
  overflow: hidden;
  bottom: 0;
  right: 0;
  background-color: #ffc145;
  border-radius: 32px 4px 0 4px;
}

.go-arrow {
  margin-top: 7px;
  margin-right: -4px;
  color: #0e3d4d;
  font-family: courier, sans;
}

.card-mobile {
  display: block;
  position: relative;
  width: 262px;
  border-radius: 20px;
  height: 12rem;
  padding: 32px 12px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  background-size: cover;
  .destination-text {
    position: absolute;
    bottom: 12px;
    left: 12px;
    right: 12px;
    width: -webkit-fill-available;
    align-items: flex-end;
    display: flex;
    .icon {
      transform: rotate(-45deg);
    }
  }
  .mb-destination-text {
    background-color: #0e3d4d94;
    backdrop-filter: blur(7px);
    color: white !important;
    border-radius: 20px 20px 20px 0;
    padding: 4px 12px;
    font-weight: 600;
    color: #0e3d4d;
    width: -moz-max-content;
    width: max-content;
  }
  .arrow-container {
    background-color: #0e3d4d94;
    backdrop-filter: blur(7px);
    color: white;
    border-radius: 50px;
    padding: 6px;
    width: fit-content;
  }
}

.card1 {
  p {
    font-family: "Playfair Display", serif;
    font-weight: 500;
    color: #0e3d4d;
    visibility: hidden;
    position: absolute;
    bottom: 10px;
    font-size: 1.75rem;
    max-width: 49vw;
  }
  display: block;
  position: relative;
  width: 262px;
  background-color: #f2f8f9;
  border-radius: 20px;
  height: 12rem;
  padding: 32px 12px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  background-size: cover;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: -16px;
    right: -16px;
    background: #ffc145;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }
  &:hover:before {
    transform: scale(21);
  }
}

.card1:hover {
  p {
    transition: all 0.3s ease-out;
    visibility: visible;
  }
}

.d-container {
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin: 0 -20px;
  padding: 0 12px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.columns {
  align-items: flex-start;
}

@media (max-width: 768px) {
  ::v-deep {
    .quote-modal .card {
      background-color: hsl(0deg, 0%, 100%);
      border-radius: 0.25rem;
      min-height: 100vh;
      box-shadow: none;
      color: hsl(0deg, 0%, 29%);
      max-width: 100%;
      position: relative;
      padding: 0 20px;
    }
  }

  .title {
    font-size: 1.6rem;
}

  .order-1-mobile {
    order: 1;
  }
  .order-2-mobile {
    order: 2;
    width: -webkit-fill-available;
  }
  .columns {
    display: flex;
    flex-direction: column;
  }

  .modal-content {
    max-height: 100vh;
    padding: 0;

    ::v-deep {
      .bg-light {
        background-color: white !important;
        border-top: 1px solid #ededed !important;
        padding: 1.75rem;
      }
      .b-image-wrapper > img {
        height: 17rem;
      }
    }
  }
}
</style>
