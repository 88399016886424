var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"activities-page mb-6 pb-6 mt-6"},[_vm._m(0),_c('ActivitiesContainer',{ref:"childComponent",attrs:{"is-full-width":true,"Experiences":[
      'D1YH6p0pVThgornEsYhe',
      'GqLu48OlVVI3sH6BwGZm',
      'SfuJZtcgdYvaU2MgTbwj',
      'JDO7uP17SaMlx8Y21gl8',
    '63mFURw6KKq6ZFTmGtMQ',
    'FyBrCr0owDZFSWu7mag7',
    't78nJPOAbsW4DuwDnZa8'],"Tickets":[]}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-6",attrs:{"data-aos":"fade-right","data-aos-easing":"ease-in-out","data-aos-mirror":"true","data-aos-once":"false"}},[_c('p',{staticClass:"title mb-5"},[_vm._v("Day trips in Portugal")]),_c('p',{staticClass:"mb-4"},[_vm._v("Uncover the true beauty of Portugal with a private guide, who’ll take you on a personalized journey to reveal the country’s most captivating secrets. ")])])
}]

export { render, staticRenderFns }