<template>
  <section class="modal-content">
    <div v-if="data">
      <!-- <b-carousel :indicator-inside="false">
        <b-carousel-item v-for="(item, i) in data.photos" :key="i">
          <b-image class="image" :src="data.photos[i]"></b-image>
        </b-carousel-item>
        <template>
          <b-image class="al image" :src="data.photos[i]"></b-image>
        </template>
      </b-carousel> -->

 <b-carousel class="mb-6 mt-6" v-if="data.photos.length">
   <b-carousel-item v-for="(carousel, i) in data.photos" :key="i">
          <b-image class="image" :src="data.photos[i]"></b-image>
    </b-carousel-item>
</b-carousel>

      <p class="title mb-5" :class="{'mt-6': !data.photos.length}">{{ data.name }}</p>
      <p class="text mb-6">{{ data.description }}</p>

      <div class="mt-5 mb-6">
        <div class="is-flex is-align-items-center mb-3">
          <img src="../assets/clock.svg" class="mr-2" />
          <b>Opening times</b>
        </div>

        <div class="display-flex-space-btw mb-3">
          <p>open - close</p>
          <p>{{ data.hours }}</p>
        </div>

        <div v-if="data.lastAdmission" class="display-flex-space-btw">
          <p>last admission</p>
          <p>{{ data.lastAdmission }}</p>
        </div>

        <p class="mt-5 mb-5 label" v-if="data.hoursExtra">{{data.hoursExtra}}<a target="_blank" :href="data.website"> {{data.website}}</a>.</p>
        <p class="mt-5 mb-5 label" v-else-if="data.website">For updated information visit <a target="_blank" :href="data.website"> {{data.website}}</a>.</p>
      </div>

      <div class="mt-5 mb-5">
        <div class="is-flex is-align-items-center mb-3">
          <img src="../assets/dollar-circle.svg" class="mr-2" />
          <b>Price</b>
        </div>

        <div class="display-flex-space-btw">
          <p>{{ data.fee }}</p>
        </div>
      </div>

      <div v-if="data.activities.length" class="mt-6 bg-light">
        <p class="subtitle mb-5">Ways to experience ({{ data.activities.length }})</p>

        <ActivitiesContainer
          ref="childComponent"
          class="d-container"
          :is-full-width="true"
          :Experiences="data.activities"
          :with-animation="false"
        />
        <!--  <ActivitiesContainer
          ref="childComponent"
          :is-full-width="false"
          :Experiences="data.activities"
          :Tickets="data.tickets"
        /> -->
      </div>
    </div>
    <div v-else>No data</div>
  </section>
</template>

<script>
import ActivitiesContainer from "./ActivitiesContainer.vue";
import { DestinationsData } from "@/enums/DestinationsData";
export default {
  props: ["id"],
  components: {
    ActivitiesContainer,
  },
  data() {
    return {
      DestinationsData,
    };
  },
  computed: {
    data() {
      console.log(this.DestinationsData, this.id);
      const index = this.DestinationsData.findIndex(
        (destination) => destination.id === this.id
      );
      console.log(index);
      if (index < 0) return [];
      console.log(this.DestinationsData[index]);
      return this.DestinationsData[index];
    },
  },
};
</script>

<style scoped lang="scss">
.bg-light{
  background-color: #FBFCFF;
  margin: 0 -20px;
  padding: 1.75rem 1rem;
}

::v-deep{
  .b-image-wrapper > img {
  height: 25rem;
  border-radius: 13px;
}
}


.title,
.text,
p,
b {
  color: #0e3d4d;
}

.title {
  font-size: 1.75rem;
}

.subtitle{
    font-size: 1.5rem
}


.title,
.subtitle {
    font-family: "Playfair Display", serif; 
   font-weight: 500;
}

.text {
  font-weight: 300;
}

.label{
  font-weight: 300;
  font-size:.8rem;
  a{
    color: #4f6e789e
  }
}

</style>
